import { G } from '@mobily/ts-belt';
import { FlowId, flows } from './flows';

/**
 * Builds the router path from a flow and identifier
 * @param flow flowId
 * @param identifier of the step, as an index of the flow or a stepId name
 * @param fallback the path returned if identifier does not exist in flow
 * @returns the path for the flow and step identifier
 */
export const getStepPath = ({
  flow,
  identifier,
  fallback = '/',
}: {
  flow: FlowId;
  identifier: number | string;
  fallback?: string;
}) => {
  const step = G.isString(identifier)
    ? flows[flow].find(f => f.step === identifier)?.step
    : flows[flow]?.[identifier]?.step;

  return G.isUndefined(step) ? fallback : `/get-your-fix/${flow}/${step}`;
};

export const getFirstStepPath = (flow: FlowId) =>
  getStepPath({ flow, identifier: 0 });

export const getSteps = ({
  flow,
  currentStepIndex,
}: {
  flow: FlowId;
  currentStepIndex: number;
}) => ({
  currentStepIndex,
  nextStepPath: getStepPath({ flow, identifier: currentStepIndex + 1 }),
  previousStepPath: getStepPath({
    flow,
    identifier: currentStepIndex - 1,
    fallback: '/profile-note',
  }),
});
