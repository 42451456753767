import { SimpleScreenViewEvent } from '../types/events';

type Platform = SimpleScreenViewEvent['platform'];

// We opt not to declare this on the global window namespace, to avoid conflicting with
// global declarations made in consuming apps/libs:
export interface SfixWindow {
  sfixMobile?: {
    isAndroid?: () => boolean;
    isiOS?: () => boolean;
  };
}

const getWindow = () => {
  if (window) return window as SfixWindow;

  return undefined;
};

const getPlatform = (): Platform => {
  const sfixMobile = getWindow()?.sfixMobile;

  if (sfixMobile?.isAndroid?.()) {
    return 'android';
  }
  if (sfixMobile?.isiOS?.()) {
    return 'iOS';
  }

  return 'web';
};

export default getPlatform;
