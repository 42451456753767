import React, {
  type ElementType,
  type ReactNode,
  forwardRef,
  type FunctionComponent,
} from 'react';
import classNames from 'classnames';
import ButtonBase, {
  ExtendableButtonBaseProps,
  ButtonBaseProps,
} from '../ButtonBase';
import { DecorativeIconProvider } from '../IconBase';
import logger from '../_internal/logger';
import {
  processStylingProps,
  type DeprecatedAndDangerousStylingProps,
  type ResponsiveValue,
  getResponsiveValue,
} from '../_internal/styling';
import type { PolymorphicRef } from '../_internal/components';
import useScreenSize from '../_internal/useScreenSize';

import styles from './floatingAction.module.scss';

interface ChildrenLabel {
  /**
   * The ARIA label for accessibility
   */
  'aria-label'?: string;
  /**
   * Label text next to the icon in the floating action button
   */
  children: ReactNode;
  /**
   * Whether or not to hide the specified label text
   */
  hideLabel?: ResponsiveValue<boolean>;
}
interface AriaLabel {
  'aria-label': string;
  children?: undefined;
  hideLabel?: undefined;
}

// Ensure that either `children` or `aria-label` are passed
type LabelProps = ChildrenLabel | AriaLabel;

interface CommonProps extends DeprecatedAndDangerousStylingProps {
  /**
   * An icon to place after the `label`.
   */
  icon?: ReactNode;
  /**
   * An icon to place before the `label`.
   */
  startIcon?: ReactNode;
  /**
   * The size of the floating action button.
   *
   * @deprecated
   */
  size?: ResponsiveValue<'large' | 'small'>;
  /**
   * Style of the floating action button
   */
  variant?: ResponsiveValue<'default' | 'secondary'>;
}

type Props = CommonProps & LabelProps;

export type FloatingActionProps<C extends ElementType = 'button'> =
  ExtendableButtonBaseProps<C, Props>;

type FloatingActionComponent = FunctionComponent &
  (<C extends ElementType = 'button'>(
    props: FloatingActionProps<C>,
  ) => React.ReactElement | null);

/**
 * A floating action button (aka FAB) is a clickable icon that is displayed at a fixed location on the page.
 */
export const FloatingAction: FloatingActionComponent = forwardRef(
  <C extends ElementType>(
    {
      'aria-label': ariaLabel,
      children,
      hideLabel = { sm: true, md: false },
      icon,
      size,
      startIcon,
      variant = 'default',

      // ideally we'd leave these margin props in `rootProps`, but pulling them
      // out reduces the complexity of the type. Without this typescript errors
      // saying the union type is too complex.
      m,
      mt,
      mb,
      my,
      ml,
      mr,
      mx,
      ...rootProps
    }: FloatingActionProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    if (size !== undefined) {
      logger.warn('`size` on `<FloatingAction />` is deprecated.');
    }

    const screenSize = useScreenSize();
    const responsiveVariant = getResponsiveValue(
      screenSize,
      variant,
      'default',
    );
    const responsiveHideLabel = getResponsiveValue(
      screenSize,
      hideLabel,
      false,
    );
    const className = classNames(styles[responsiveVariant], {
      [styles['with-text']]: !!children && !responsiveHideLabel,
      [styles['no-text']]: !children || responsiveHideLabel,
      [styles['end-icon']]: icon,
      [styles['start-icon']]: startIcon,
    });

    const buttonBaseProps = processStylingProps(rootProps, 'FloatingAction', {
      stylingProps: 'warn',
      dangerousStylingProps: 'rewrite',
    }) as ButtonBaseProps<C>;

    // TypeScript is already verifying that one of these is passed, but for those
    // not using TypeScript, we need to make sure that the button is accessible.
    if (!children && !ariaLabel) {
      logger.warn(
        'FloatingAction must specify a label (via `children`) or `aria-label` to satisfy accessibility needs',
      );
    }

    return (
      <ButtonBase
        className={className}
        aria-label={ariaLabel}
        ref={ref}
        m={m}
        mt={mt}
        mb={mb}
        my={my}
        ml={ml}
        mr={mr}
        mx={mx}
        {...buttonBaseProps}
      >
        {startIcon && (
          <DecorativeIconProvider>{startIcon}</DecorativeIconProvider>
        )}

        {children}
        {icon && <DecorativeIconProvider>{icon}</DecorativeIconProvider>}
      </ButtonBase>
    );
  },
);

FloatingAction.displayName = 'FloatingAction';

export default FloatingAction;
