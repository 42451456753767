export const formatMessage = (message: string) =>
  `[${process.env.NEXT_PUBLIC_APP_NAME}]: ${message}`;

export type LoggerArgs = {
  team?:
    | 'first-touch'
    | 'incentives'
    | 'client-onboarding'
    | 'client-data-platform'
    | 'discover';
  feature?: 'onboarding' | 'lower-fix-funnel' | 'registration' | 'stylefile';
  message: string;
  /**
   * using the intersection of logging levels provided by `@datadog/browser-logs` and `winston`
   * https://docs.datadoghq.com/logs/log_collection/javascript/#generic-logger-function
   * https://github.com/winstonjs/winston#logging-levels
   */
  level?: 'info' | 'debug' | 'error' | 'warn';
  context?: Record<string, unknown>;
};

/**
 * we had aspirations for a `universalLogger`, but that didn't play nice
 * with our bundler, instead of passing errors up the chain, we'll explicitly
 * pass a callback to the logger at the top of the chain
 */
export type LoggerCallback = (args: LoggerArgs) => void;

export const DEFAULT_LEVEL = 'info';
