import * as React from "react";
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
import IconBase, { IconProps } from '../IconBase';
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconAddInCircle24 = (baseProps: IconProps, ref: Ref<SVGSVGElement>) => {
  return <IconBase {...baseProps}>
        {({
      title,
      titleId,
      ...props
    }: SVGProps<SVGSVGElement> & SVGRProps) => <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fillRule="evenodd" clipRule="evenodd" d="M12 2.29995C6.64279 2.29995 2.29995 6.64279 2.29995 12C2.29995 17.3571 6.64279 21.7 12 21.7C17.3571 21.7 21.7 17.3571 21.7 12C21.7 6.64279 17.3571 2.29995 12 2.29995ZM0.699951 12C0.699951 5.75913 5.75913 0.699951 12 0.699951C18.2408 0.699951 23.3 5.75913 23.3 12C23.3 18.2408 18.2408 23.3 12 23.3C5.75913 23.3 0.699951 18.2408 0.699951 12ZM12.7998 6.57996V7.37996V10.82H16.5H17.3V12.42H16.5H12.7998V16.38V17.18H11.1998V16.38V12.42H7.49995H6.69995V10.82H7.49995H11.1998V7.37996V6.57996H12.7998Z" fill="currentColor" /></svg>}
      </IconBase>;
};
const ForwardRef = forwardRef(IconAddInCircle24);
export default ForwardRef;