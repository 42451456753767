import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Box from '../Box';
import InteractableContainer from '../InteractableContainer';
import { getResponsiveValue, ResponsiveValue } from '../_internal/styling';
import Text from '../Text';
import Button from '../Button';
import IconCloseX16 from '../icons/IconCloseX16';
import IconArrowRight16 from '../icons/IconArrowRight16';
import styles from './slimBanner.module.scss';
import useScreenSize from '../_internal/useScreenSize';
import { useTheme } from '../ThemeProvider';

export const VARIANTS = [
  'info-light',
  'promo-dark',
  'dark',
  'error-dark',
  'citrus',
] as const;

export type Variant = (typeof VARIANTS)[number];

interface CtaUrlRequired {
  /**
   * Optional CTA text displayed after the main banner text. Not visible on small screens
   */
  text?: string;
  /**
   * URL path for the banner to navigate to
   */
  url: string;
}

interface CtaOnClickRequired {
  /**
   * Optional CTA text displayed after the main banner text. Not visible on small screens
   */
  text?: string;
  /**
   * Call back function for when the banner is clicked
   */
  onClick: () => void;
}

export interface SlimBannerProps {
  /**
   * A call-to-action for the banner
   * @ignore The conversion to React PropTypes does not work
   * with this union so just exclude
   */
  cta?: CtaUrlRequired | CtaOnClickRequired;
  /**
   * A custom icon next to the banner text that informs users of the kind of banner message at a glance.
   */
  icon?: ReactNode;
  /**
   * Callback function for when the close button is clicked.
   * The close button is omitted when this handler is not specified.
   */
  onClose?: () => void;
  /**
   * The banner message
   */
  text: ResponsiveValue<string>;
  /**
   * The banner style
   *
   * @default 'info-light'
   */
  variant?: Variant;
}

/**
 * Slim banners are shown above the rest of the screen content. They are reserved for quick
 * actions and are meant to show information around customer's Fix status, promotional credits, etc. They should
 * only contain one line of copy.
 *
 * The entire banner element is interactive. A `cta.url` or `cta.onClick` must be provided.
 */
const SlimBanner = ({
  text,
  onClose,
  cta,
  icon,
  variant = 'info-light',
}: SlimBannerProps) => {
  const isRebranded = useTheme() === 'brand-2024-full';
  const screenSize = useScreenSize();

  const bannerText = getResponsiveValue(screenSize, text);

  const contentProps = (() => {
    if (cta) {
      if ('onClick' in cta)
        return { as: 'button', type: 'button', onClick: cta.onClick } as const;

      return { as: 'a', href: cta.url } as const;
    }

    return {};
  })();

  const isInteractive = Boolean(cta);

  const ctaVariant = (() => {
    if (isRebranded) {
      return variant === 'info-light' ||
        variant === 'citrus' ||
        variant === 'promo-dark'
        ? 'text'
        : 'text-inverse';
    }

    return variant === 'info-light' || variant === 'citrus'
      ? 'text'
      : 'text-inverse';
  })();

  return (
    <InteractableContainer
      variant={variant}
      onClickIcon={onClose}
      icon={{
        sm: <IconCloseX16 />,
      }}
      iconVAlign="middle"
      iconLabel="close banner"
      iconVariant="inherited"
      data-testid="slim-banner"
    >
      <Box
        className={classNames(styles.content, styles[variant], {
          [styles['is-interactive']]: isInteractive,
        })}
        data-testid="content"
        {...contentProps}
      >
        <Box
          className={classNames(styles['content-wrapper'], {
            [styles['has-close']]: onClose,
          })}
        >
          <Text height="standard" family="medium" scale="3">
            {icon && (
              <Box className={`${styles.icon} ${styles['content-icon']}`}>
                {icon}
              </Box>
            )}
            {bannerText}
            {cta?.text && screenSize !== 'sm' && (
              <Button as="span" ml={1} variant={ctaVariant}>
                {cta.text}
              </Button>
            )}
          </Text>
          {!onClose && screenSize === 'sm' && (
            <Box className={styles.icon}>
              <IconArrowRight16
                purpose="decorative"
                color="inherit"
                title="icon arrow right"
              />
            </Box>
          )}
        </Box>
      </Box>
    </InteractableContainer>
  );
};

export default SlimBanner;
