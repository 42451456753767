import React, { ComponentType, ReactNode, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Bugsnag from '@bugsnag/js';
import type { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import {
  CssBase,
  setAppElement,
  setServerSideRendered,
  ThemeProvider,
} from '@stitch-fix/mode-react';
import ErrorPage from '../ErrorPage';
import { ClientAuthProvider } from '../ClientAuthContext';
import { ClientI18nProvider, useClientI18n } from '../ClientI18nContext';
import { createGraphQLApiProvider } from '../GraphQLApiProvider';
import ClientGtm from '../ClientGtm';
import { getSafeClientInfo } from '../utils/bugsnag';
import { hasLocalAssets } from '../utils/settings';

// The magic param allows us to automatically log clients in when they get an email from us
const magicAuthParam = 'email_validation';

// Let `mode-react` know that this app is server-side rendered
// so that media query utilities are handled properly.
// See: https://mode-react.daylight.stitchfix.com/?path=/story/ssr--page
setServerSideRendered();

export interface ClientAppProps extends AppProps {
  /**
   * The GraphQL API Provider to wrap around the page.
   * Uses a default Provider when unspecified (`undefined`).
   * Use `null` to have no Provider.
   */
  GraphQLApiProvider?: ComponentType<{ children: ReactNode }> | null;
}

const Meta = () => {
  const { locale, region } = useClientI18n();

  return (
    <Head>
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="stitch-fix:locale" content={locale} />
      <meta name="stitch-fix:region" content={region} />
      <meta name="apple-itunes-app" content="app-id=1022579925" />
      <meta
        name="facebook-domain-verification"
        content="x5k145dyv664rwswb6vlof99nwjhkd"
      />
    </Head>
  );
};

const EmptyGraphQLApiProvider = ({ children }: { children: ReactNode }) => (
  <>{children}</>
);

// We want to only have a single copy of this so if `ClientApp` re-renders, we
// don't remount the app.
let bugsnagErrorBoundary: BugsnagErrorBoundary | undefined;

const getErrorBoundary = () => {
  if (!bugsnagErrorBoundary && Bugsnag.isStarted()) {
    bugsnagErrorBoundary =
      Bugsnag.getPlugin('react')?.createErrorBoundary(React);
  }

  return bugsnagErrorBoundary;
};

const MaybeErrorBoundary = ({ children }: { children: ReactNode }) => {
  // If we can get a bugsnag error boundary, we'll wrap the `children` with it.
  // Otherwise, we'll just render the `children`.
  const ErrorBoundary = getErrorBoundary();

  if (ErrorBoundary) {
    return (
      <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
    );
  }

  return <>{children}</>;
};

const useMagicAuth = () => {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const query = { ...router.query };

      if (query[magicAuthParam]) {
        // We want to remove the magicAuthParam off of URLs so they are not shared
        // If someone copies the URL out of the browser
        delete query[magicAuthParam];

        router.replace({ query }, undefined, { shallow: true });
      }
    }
    // We don't want to include `router` in the deps because it causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, router.query, router.replace]);
};

const useAddBugsnagMetaData = () => {
  const { locale, region } = useClientI18n();

  // After the app has mounted, we want to add some more metadata to Bugsnag
  // (auth & i18n info)

  useEffect(() => {
    if (Bugsnag.isStarted()) {
      const safeClientInfo = getSafeClientInfo();

      if (safeClientInfo) {
        Bugsnag.setUser(`${safeClientInfo.clientId}`);
        Bugsnag.addMetadata('client', safeClientInfo);
      }

      Bugsnag.addMetadata('i18n', { locale, region });
    }
  }, [locale, region]);
};

/**
 * Base custom Next App for client-facing applications. It provides client auth
 * information in the React content as well as Mode React base styling
 */
export const ClientApp = ({
  Component,
  GraphQLApiProvider: RequestedGraphQLApiProvider,
  pageProps,
}: ClientAppProps) => {
  const GraphQLApiProvider = useMemo(() => {
    if (RequestedGraphQLApiProvider === undefined) {
      // Ensure a unique apollo client is created for each SSR request, but is memoized in-between
      // renders on the client-side
      return createGraphQLApiProvider().GraphQLApiProvider;
    }

    if (RequestedGraphQLApiProvider === null) {
      return EmptyGraphQLApiProvider;
    }

    return RequestedGraphQLApiProvider;
  }, [RequestedGraphQLApiProvider]);

  useEffect(() => {
    // Sets the `root` element for Modals in mode-react
    setAppElement('#root');
  }, []);

  useMagicAuth();
  useAddBugsnagMetaData();

  // We want to disable GTM in test and dev environments. In e2e browserstack tests, it causes bad
  // interactions with mock-service-worker and next middleware, causing pages to continuously
  // refresh, and makes a lot of unnecessary requests for tracking scripts.
  const showGTM = !hasLocalAssets();

  // Grab the theme that may have been set by `ssrThemeProps()`:
  // eslint-disable-next-line no-underscore-dangle
  const theme = pageProps.__THEME__ || 'brand-2024-full';

  return (
    <MaybeErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBase>
          <ClientAuthProvider>
            <ClientI18nProvider>
              <GraphQLApiProvider>
                <Meta />
                {showGTM && <ClientGtm />}
                <div id="root">
                  <Component {...pageProps} />
                </div>
              </GraphQLApiProvider>
            </ClientI18nProvider>
          </ClientAuthProvider>
        </CssBase>
      </ThemeProvider>
    </MaybeErrorBoundary>
  );
};

export default ClientApp;
