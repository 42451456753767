import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import isDeepEqual from 'fast-deep-equal/react';
import { getAndTouchActiveSession } from '../activeSession';
import getClientId from '../getClientId';
import getPlatform from '../getPlatform';
import getTimestamp from '../getTimestamp';
import getUTMParams from '../getUTMParams';
import getViewportSize from '../getViewportSize';
import reportEvent from '../reportEvent';
import useInView from '../useInView';
import {
  SimpleScreenViewEvent,
  SimpleSelectEvent,
  SimpleViewEvent,
} from '../types/events';
import DistributiveOmit from '../types/distributiveOmit';

type ParentViews = SimpleViewEvent[];

type ScreenViewContext = {
  screenView: SimpleScreenViewEvent | null;
};

const ScreenViewContext = createContext<ScreenViewContext>({
  screenView: null,
});

type ViewContext = {
  parentViews: ParentViews;
};

const ViewContext = createContext<ViewContext>({
  parentViews: [],
});

// Some data is managed by Event Reporter runtime code. This data does not need
// to be manually passed into the hooks, and is omitted from hook data
// arguments.
type UseScreenViewEventData = DistributiveOmit<
  SimpleScreenViewEvent,
  | 'client_id'
  | 'platform'
  | 'pte_id'
  | 'referrer'
  | 'type'
  | 'url'
  | 'user_agent'
  | 'utm_params'
  | 'viewport_size'
  | 'visitor_id'
>;

type EventProviderProps = {
  children: ReactNode;
};

const useScreenViewEvent = (data: UseScreenViewEventData) => {
  const dataRef = useRef(data);
  const screenViewEventRef = useRef<ScreenViewContext['screenView']>(null);

  const isFirstRender = !screenViewEventRef.current;
  const dataChanged = !isDeepEqual(data, dataRef.current);
  const isSSR = typeof window === 'undefined';

  // Use refs to maintain referential identity between renders so we don't emit
  // duplicate events or trigger renders with the hooks below. When a new data
  // object is passed, break equality and trigger hook updates.
  if ((isFirstRender || dataChanged) && !isSSR) {
    dataRef.current = data;

    screenViewEventRef.current = {
      ...data,
      client_id: getClientId(),
      platform: getPlatform(),
      referrer: window.document.referrer,
      pte_id: uuid(),
      type: 'screen_view',
      url: window.location.href,
      user_agent: window.navigator.userAgent,
      utm_params: getUTMParams(),
      viewport_size: getViewportSize(),
      // TODO: If our runtime systems are working correctly, this will always be a
      // string. Decide whether to expect that in typing or to allow undefined and
      // send a bugsnag if it's not there.
      visitor_id: Cookies.get('visitor_uuid') as string,
    };
  }

  const memoizedScreenViewEvent = screenViewEventRef.current;

  useEffect(() => {
    // `memoizedScreenViewEvent` will be null in an SSR context, so the event won't be reported
    // until this hook runs during client-side hydration
    if (memoizedScreenViewEvent) {
      reportEvent({
        ...memoizedScreenViewEvent,
        active_session_id: getAndTouchActiveSession(),
        screen_view: memoizedScreenViewEvent,
        timestamp: getTimestamp(),
      });
    }
  }, [memoizedScreenViewEvent]);

  const EventProvider = useCallback<FunctionComponent<EventProviderProps>>(
    ({ children }) => (
      <ScreenViewContext.Provider
        value={{ screenView: memoizedScreenViewEvent }}
      >
        {children}
      </ScreenViewContext.Provider>
    ),
    [memoizedScreenViewEvent],
  );

  EventProvider.displayName = 'EventProvider';

  return { EventProvider };
};

type ReportSelectEventData = DistributiveOmit<
  SimpleSelectEvent,
  'pte_id' | 'type'
>;

// useReportSelectEvent returns a function to record select events with
// immediate view context. It's extracted to share functionality between
// useSelectEvent and useViewEvent. It should never be exposed to end users.
const useReportSelectEvent = (parentViews: ParentViews) => {
  const { screenView } = useContext(ScreenViewContext);

  const reportSelectEvent = (data: ReportSelectEventData) => {
    reportEvent({
      ...data,
      active_session_id: getAndTouchActiveSession(),
      parent_views: parentViews,
      pte_id: uuid(),
      screen_view: screenView as SimpleScreenViewEvent,
      timestamp: getTimestamp(),
      type: 'select',
    });
  };

  return reportSelectEvent;
};

const useSelectEvent = () => {
  const { parentViews } = useContext(ViewContext);
  const reportSelectEvent = useReportSelectEvent(parentViews);

  return { reportSelectEvent };
};

type UseViewEventData = DistributiveOmit<SimpleViewEvent, 'pte_id' | 'type'>;

const useViewEvent = (data: UseViewEventData) => {
  const { screenView } = useContext(ScreenViewContext);
  const { parentViews } = useContext(ViewContext);

  const dataRef = useRef(data);
  const parentViewsRef = useRef(parentViews);
  const inViewCallbackRef = useRef<(() => void) | undefined>();
  const dataChanged = !isDeepEqual(data, dataRef.current);

  // Use refs to maintain referential identity between renders so we don't emit
  // duplicate events or trigger renders with the hooks below. When a new data
  // object is passed, break equality and trigger hook updates.
  if (!parentViewsRef.current || !inViewCallbackRef.current || dataChanged) {
    dataRef.current = data;

    // If it exists, convert order to 1-index for algos consumption
    const order = data.order ? { order: { index: data.order.index + 1 } } : {};

    const event: SimpleViewEvent = {
      ...data,
      ...order,
      type: 'view',
      pte_id: uuid(),
    };

    parentViewsRef.current = [event, ...parentViews];

    inViewCallbackRef.current = () => {
      reportEvent({
        ...event,
        active_session_id: getAndTouchActiveSession(),
        parent_views: parentViews,
        screen_view: screenView as SimpleScreenViewEvent,
        timestamp: getTimestamp(),
      });
    };
  }

  const { ref } = useInView(inViewCallbackRef.current);

  const memoizedParentViews = parentViewsRef.current;

  const reportSelectEvent = useReportSelectEvent(memoizedParentViews);

  const EventProvider = useCallback<FunctionComponent<EventProviderProps>>(
    ({ children }) => (
      <ViewContext.Provider
        value={{
          parentViews: memoizedParentViews,
        }}
      >
        {children}
      </ViewContext.Provider>
    ),
    [memoizedParentViews],
  );

  EventProvider.displayName = 'EventProvider';

  return { EventProvider, ref, reportSelectEvent };
};

export { useScreenViewEvent, useSelectEvent, useViewEvent };
export type { UseScreenViewEventData, ReportSelectEventData, UseViewEventData };
